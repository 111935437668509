import React from 'react';
import styled from '@emotion/styled';
import Container from './Container';
import { ResponsiveContext } from '../providers/responsive';
import { ThemeContext } from '../providers/themes';

const SectionHeader = styled.div((props) => {
  const commonStyles = {
    backgroundColor: props.theme.primaryColor,
    color: props.theme.text,
  };
  if (props.responsive.isDesktopUp) {
    return {
      ...commonStyles,
      borderLeft: `solid 1px ${props.theme.lines}`,
      borderRight: `solid 1px ${props.theme.lines}`,
    };
  }
  return {
    ...commonStyles,
    borderTop: `solid 1px ${props.theme.lines}`,
    borderBottom: `solid 1px ${props.theme.lines}`,
  };
});

const SectionBody = styled.div((props) => ({
  ...props.bodyStyles,
}));

const Section = ({
  title,
  slug,
  paddingTop = 0,
  paddingBottom = 0,
  bodyStyles,
  children,
  className = '',
  ...otherProps
}) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <ResponsiveContext.Consumer>
        {(responsive) => (
          <section className={`section ${className}`} {...otherProps}>
            {title && (
              <SectionHeader
                {...{ responsive }}
                {...{ theme }}
                className="section__title"
              >
                <Container>
                  <h2>{title}</h2>
                </Container>
              </SectionHeader>
            )}
            <SectionBody
              className="section__body"
              bodyStyles={bodyStyles}
              hasTitle={title}
            >
              {children}
            </SectionBody>
          </section>
        )}
      </ResponsiveContext.Consumer>
    )}
  </ThemeContext.Consumer>
);

export default Section;
