// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-francisco-avila-jsx": () => import("./../../../src/pages/francisco-avila.jsx" /* webpackChunkName: "component---src-pages-francisco-avila-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-templates-single-portfolio-jsx": () => import("./../../../src/templates/SinglePortfolio.jsx" /* webpackChunkName: "component---src-templates-single-portfolio-jsx" */)
}

