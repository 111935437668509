import React from 'react';
import styled from '@emotion/styled';

const StyledFlex = styled.div((props) => ({
  display: 'flex',
  ...props.styles,
}));

const Flex = ({
  direction = 'horizontal',
  justifyContent = 'center',
  alignItems = 'center',
  children,
  ...otherProps
}) => (
  <StyledFlex
    styles={{
      direction,
      justifyContent,
      alignItems,
    }}
    {...otherProps}
  >
    {children}
  </StyledFlex>
);

export default Flex;
