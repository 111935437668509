import React from 'react';
import styled from '@emotion/styled';

const StyledGrid = styled.div((props) => ({
  display: 'grid',
  ...props.styles,
}));

const Grid = ({
  gap = '1rem',
  gridTemplateColumns = '1fr',
  gridTemplateRows = '1fr',
  styles,
  children,
  ...otherProps
}) => (
  <StyledGrid
    styles={{
      gap,
      gridTemplateColumns,
      gridTemplateRows,
      ...styles,
    }}
    {...otherProps}
  >
    {children}
  </StyledGrid>
);

export default Grid;
