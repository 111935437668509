import React from 'react';
import styled from '@emotion/styled';
import LogoSVG from '../images/logo.svg';
import { ThemeContext } from '../providers/themes';

const LogoWrapper = styled.div((props) => ({
  color: props.theme.text,
}));

const Logo = (props) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <LogoWrapper {...{ theme }} {...props}>
        <LogoSVG />
      </LogoWrapper>
    )}
  </ThemeContext.Consumer>
);

export default Logo;
