import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import styled from '@emotion/styled';
import { PopupButton } from 'react-calendly';

import { ThemeContext } from '../providers/themes';
import { ResponsiveContext } from '../providers/responsive';
import SVGWrapper from './SVGWrapper';
import Container from './Container';
import Grid from './Grid';
import Flex from './Flex';
import LogoSVG from '../images/logo.svg';
import * as colors from '../colors';
import * as themes from '../themes';

const StyledNavBar = styled.div((props) => ({
  borderBottom: `solid 1px ${props.theme.lines}`,
  backgroundColor: props.theme.primaryColor,
}));

const Logo = styled(SVGWrapper)((props) => ({
  color: props.theme.text,
}));

const ColorSelectorsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
});

const ColorSelector = styled.button((props) => ({
  backgroundColor: props.color,
  border: `solid 1px ${props.theme.lines}`,
}));

const StyledPopupButton = styled(PopupButton)((props) => ({
  backgroundColor: 'transparent',
  color: props.theme.text,
  border: `solid 1px ${props.theme.lines}`,
  padding: '0.813rem 1.688rem',
  textTransform: 'uppercase',
  cursor: 'pointer',
  letterSpacing: 0.5,
}));

const ActionsContainer = styled(Grid)((props) => ({
  alignItems: 'center',
}));

const Navbar = ({ intl, ...otherProps }) => {
  const responsive = useContext(ResponsiveContext);
  const { changeTheme, theme } = useContext(ThemeContext);
  return (
    <StyledNavBar className="navbar" {...{ theme }} {...otherProps}>
      <Container>
        <Flex justifyContent="space-between">
          <Link to="/" className="logo-container">
            <Logo color={theme.text}>
              <LogoSVG />
            </Logo>
          </Link>
          <ActionsContainer
            gridTemplateColumns={responsive.isDesktopUp ? 'auto auto' : '1fr'}
            gap="3.188rem"
          >
            <ColorSelectorsContainer {...{ theme }}>
              <ColorSelector
                className="color-selector"
                {...{ theme }}
                color={colors.YELLOW}
                onClick={() => {
                  changeTheme(themes.themeYellow);
                }}
              />
              <ColorSelector
                className="color-selector"
                {...{ theme }}
                color={colors.ORANGE}
                onClick={() => {
                  changeTheme(themes.themeOrange);
                }}
              />
              <ColorSelector
                className="color-selector"
                {...{ theme }}
                color={colors.PINK}
                onClick={() => {
                  changeTheme(themes.themePink);
                }}
              />
              <ColorSelector
                className="color-selector"
                {...{ theme }}
                color={colors.BLACK}
                onClick={() => {
                  changeTheme(themes.themeDark);
                }}
              />
              <ColorSelector
                className="color-selector"
                {...{ theme }}
                color={colors.LIGHT_GREY}
                onClick={() => {
                  changeTheme(themes.themeLight);
                }}
              />
            </ColorSelectorsContainer>
            {responsive.isDesktopUp && (
              <StyledPopupButton
                {...{ theme }}
                responsive={responsive}
                text={intl.formatMessage({ id: 'cta-call' })}
                url={intl.formatMessage({ id: 'calendly-url' })}
              />
            )}
          </ActionsContainer>
        </Flex>
      </Container>
    </StyledNavBar>
  );
};

export default injectIntl(Navbar);
