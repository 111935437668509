import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

const ChangingWords = styled.span({
  display: 'block',
});

const WordsSwitcher = ({ words: wordsProp = [], speed = 3000 }) => {
  const [words, setWords] = useState([]);
  const [activeWordIndex, setActiveWordIndex] = useState(0);

  useEffect(() => {
    setWords(wordsProp);
  }, [wordsProp]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newWordIndex =
        activeWordIndex < words.length - 1 ? activeWordIndex + 1 : 0;
      return setActiveWordIndex(newWordIndex);
    }, speed);

    return () => {
      clearInterval(interval);
    };
  });

  return <ChangingWords>{words[activeWordIndex]}</ChangingWords>;
};

export default WordsSwitcher;
