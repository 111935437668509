import React from 'react';
import styled from '@emotion/styled';
import Navbar from './Navbar';
import { ThemeContext } from '../providers/themes';

const StyledLayout = styled.div((props) => ({
  backgroundColor: props.theme.primaryColor,
}));

const Layout = ({ location, className, title, children, ...otherProps }) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <StyledLayout
        className={`layout ${className}`}
        {...{ theme }}
        {...otherProps}
      >
        <Navbar />
        <main>{children}</main>
      </StyledLayout>
    )}
  </ThemeContext.Consumer>
);

export default Layout;
