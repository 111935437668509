import React, { useState } from 'react';
import ThemeContext from './Context';

const ThemeProvider = ({ theme: themeProp = {}, children }) => {
  const [theme, setTheme] = useState(themeProp);
  const changeTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
