import React, { useEffect, useContext } from 'react';
import { ThemeContext } from '../providers/themes';
import { themeLight, themeDark } from '../themes';

const ColorSchemeDetector = ({ children }) => {
  const { changeTheme } = useContext(ThemeContext);
  let prefersDark = false;

  if (typeof window !== `undefined`) {
    prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  useEffect(() => {
    changeTheme(prefersDark ? themeDark : themeLight);
  }, [prefersDark]);

  return children;
};

export default ColorSchemeDetector;
