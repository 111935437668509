import { createContext } from 'react';

export const ResponsiveContext = createContext({
  isMobileUp: false,
  isMobileLandscapeUp: false,
  isTabletUp: false,
  isDesktopUp: false,
  isLargeDesktop: false,

  isMobile: false,
  isMobileLandscapeDown: false,
  isTabletDown: false,
  isDesktopDown: false,

  resetBreakpoints: () => {},
});

export default ResponsiveContext;
