import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import CookieConsent from 'react-cookie-consent';
import { ThemeContext } from '../providers/themes';
import { ResponsiveContext } from '../providers/responsive';

const CookieBanner = () => {
  const location = useLocation();
  const intl = useIntl();

  return (
    <ResponsiveContext.Consumer>
      {(responsive) => (
        <ThemeContext.Consumer>
          {({ theme }) => (
            <CookieConsent
              location="bottom"
              buttonText={intl.formatMessage({ id: 'accept' })}
              cookieName="bengaru-gdpr-google-analytics"
              containerClasses="cookie-container"
              contentClasses="cookie-text"
              buttonClasses="cookie-accept"
              style={{
                background: theme.cookies?.background,
                color: theme.cookies?.text,
                alignItems: 'center',
                fontSize: '0.875rem',
                paddingLeft: 'calc(100vw * 0.10 / 2)',
                paddingRight: 'calc(100vw * 0.10 /2)',
              }}
              buttonStyle={{
                fontSize: '0.875rem',
                color: theme.cookies?.text,
                border: `solid 1px ${theme.cookies?.text}`,
                background: 'transparent',
                margin: 0,
                letterSpacing: 0.5,
                padding: '0.938rem 3.875rem',
              }}
              contentStyle={{
                margin: 0,
                flex: responsive.isDesktopUp ? '1 0 300px' : 1,
              }}
              onAccept={() => {
                initializeAndTrack(location);
              }}
            >
              {intl.formatMessage({ id: 'cookies-prompt' })}
            </CookieConsent>
          )}
        </ThemeContext.Consumer>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default CookieBanner;
