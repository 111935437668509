import React from 'react';
import styled from '@emotion/styled';

const LogoWrapper = styled.div((props) => ({
  color: props.color,
}));

const SVGWrapper = ({ color, children, ...otherProps }) => (
  <LogoWrapper color={color} {...otherProps}>
    {children}
  </LogoWrapper>
);

export default SVGWrapper;
