import React from 'react';
import { ColorSchemeDetector } from './src/components';
import { ThemeProvider } from './src/providers/themes';
import { ResponsiveProvider } from './src/providers/responsive';
import { themeLight } from './src/themes';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={themeLight}>
    <ResponsiveProvider>{element}</ResponsiveProvider>
  </ThemeProvider>
);

export const wrapPageElement = ({ element }) => (
  <ColorSchemeDetector>{element}</ColorSchemeDetector>
);
