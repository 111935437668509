import React from 'react';
import styled from '@emotion/styled';
import SloganSVG from '../images/slogan.svg';
import { ThemeContext } from '../providers/themes';

const LogoWrapper = styled.div((props) => ({
  color: props.theme.text,
}));

const Logo = ({ ...props }) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <LogoWrapper {...{ theme }} {...props}>
        <SloganSVG />
      </LogoWrapper>
    )}
  </ThemeContext.Consumer>
);

export default Logo;
