import * as colors from './colors';
import beWildDark from './lotties/home/black.json';
import beWildLight from './lotties/home/white.json';
import beWildRed from './lotties/home/red.json';
import beWildPink from './lotties/home/pink.json';
import beWildLime from './lotties/home/lime.json';

export const themeLight = {
  lines: colors.BLACK,
  text: colors.BLACK,
  primaryColor: colors.LIGHT_GREY,
  contrastColor: colors.YELLOW,
  error: colors.ORANGE,
  portfolioHover: {
    background: colors.BLACK,
    text: colors.LIGHT_GREY,
  },
  buttons: {
    background: colors.BLACK,
    text: colors.LIGHT_GREY,
  },
  cookies: {
    background: colors.YELLOW,
    text: colors.BLACK,
  },
  beWildAnimation: beWildLight,
};

export const themeDark = {
  lines: colors.GREY,
  text: colors.GREY,
  primaryColor: colors.BLACK,
  contrastColor: colors.YELLOW,
  error: colors.ORANGE,
  portfolioHover: {
    background: colors.YELLOW,
    text: colors.BLACK,
  },
  buttons: {
    background: colors.GREY,
    text: colors.BLACK,
  },
  cookies: {
    background: colors.YELLOW,
    text: colors.BLACK,
  },
  beWildAnimation: beWildDark,
};

export const themeOrange = {
  lines: colors.WHITE,
  text: colors.WHITE,
  primaryColor: colors.ORANGE,
  contrastColor: colors.PINK,
  error: colors.BLACK,
  portfolioHover: {
    background: colors.PINK,
    text: colors.BLACK,
  },
  buttons: {
    background: colors.WHITE,
    text: colors.BLACK,
  },
  cookies: {
    background: colors.PINK,
    text: colors.BLACK,
  },
  beWildAnimation: beWildRed,
};

export const themePink = {
  lines: colors.BLACK,
  text: colors.BLACK,
  primaryColor: colors.PINK,
  contrastColor: colors.ORANGE,
  error: colors.ORANGE,
  portfolioHover: {
    background: colors.ORANGE,
    text: colors.BLACK,
  },
  buttons: {
    background: colors.BLACK,
    text: colors.WHITE,
  },
  cookies: {
    background: colors.ORANGE,
    text: colors.WHITE,
  },
  beWildAnimation: beWildPink,
};

export const themeYellow = {
  lines: colors.BLACK,
  text: colors.BLACK,
  primaryColor: colors.YELLOW,
  contrastColor: colors.WHITE,
  error: colors.ORANGE,
  portfolioHover: {
    background: colors.WHITE,
    text: colors.BLACK,
  },
  buttons: {
    background: colors.BLACK,
    text: colors.WHITE,
  },
  cookies: {
    background: colors.WHITE,
    text: colors.BLACK,
  },
  beWildAnimation: beWildLime,
};
