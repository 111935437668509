import * as React from 'react';
import { ResponsiveContext } from './Context';

const ResponsiveProvider = ({
  children = undefined,
  breakpoints = {
    mobileLandscape: 576,
    tablet: 768,
    desktop: 992,
    largeDesktop: 1200,
  },
}) => {
  const [viewportWidth, updateViewportWidth] = React.useState();
  const [screenBreakpoints, changeBreakpoints] = React.useState(breakpoints);

  const { mobileLandscape, tablet, desktop, largeDesktop } = screenBreakpoints;

  React.useEffect(() => {
    updateViewportWidth(window.innerWidth);
    function handleResize() {
      updateViewportWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobileLandscapeUp = viewportWidth >= mobileLandscape;
  const isTabletUp = viewportWidth >= tablet;
  const isDesktopUp = viewportWidth >= desktop;
  const isLargeDesktop = viewportWidth >= largeDesktop;

  const isMobile = viewportWidth < mobileLandscape;
  const isMobileLandscapeDown = viewportWidth < tablet;
  const isTabletDown = viewportWidth < desktop;
  const isDesktopDown = viewportWidth < largeDesktop;

  const resetBreakpoints = (newBreakpoint) => {
    changeBreakpoints(newBreakpoint);
  };

  return (
    <ResponsiveContext.Provider
      value={{
        isMobileLandscapeUp,
        isTabletUp,
        isDesktopUp,
        isLargeDesktop,

        isMobile,
        isMobileLandscapeDown,
        isTabletDown,
        isDesktopDown,

        resetBreakpoints,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveProvider;
